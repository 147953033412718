























import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({})
export default class VideoPlayer extends Vue {
	@Prop({ default: '' })
	videoId: string;

	get embedUrl(): string {
		return `https://www.youtube.com/embed/${this.videoId}`;
	}
}
